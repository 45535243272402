$base-text-color: #515154;
$secondary-text-color: lighten($base-text-color, 20%);
$almost-white: #fefefe;
$green: #22943d;
$dark-green: #186f2d;
$orange: #dd8f14;
$beige: #efe9dc;
$backdrop-grey: rgba(58, 58, 58, .8);
$purple: #575ae3;
$facebook-blue: #1877f2;
$image-placeholder-color: rgba(0, 0, 0, .1);
$light-green-border: #449f5a;
$dark-green-border: #154d22;