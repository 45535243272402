@use "../../scss/config/colors" as c;
@use "../../scss/config/z-indexes" as z;
@use "../../scss/config/breakpoints" as bp;
@use "../../scss/config/sizes" as s;
@use "../../scss/config/spacings" as sp;
@use "../../scss/config/functions" as f;

.header,
.footer,
.main {
  transition: all .3s ease-in-out;

  .yt-player-animated & {
    filter: blur(8px);
  }

}

$close-w: 20px;

.yt-player {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z.$z-highest;
  background: c.$backdrop-grey;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  &,
  &__player-container {
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  .yt-player-opened & {
    display: flex;
  }

  .yt-player-animated & {
    opacity: 1;
  }

  &__closer-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__player-container {
    background: black;
    width: 100%;
    max-width: 1000px;
    transform-origin: center;
    transform: scale(0);
    z-index: 2;

    .yt-player-animated & {
      transform: scale(1);
      opacity: 1;
    }

    @media screen and (min-width: bp.$smallest + f.px-to-em(sp.$gutter * 2)) {
      width: 90%;
    }

    @media screen and (min-width: f.px-to-em(480px)) {
      width: 80%;
    }

    @media screen and (min-width: bp.$desktop) {
      width: 70%;
    }

  }

  &__close {
    width: $close-w;
    height: $close-w;
    position: absolute;
    right: 0;
    top: -$close-w;
    background: c.$almost-white;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    border: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: bold;
    cursor: pointer;
  }

}
