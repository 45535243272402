@use "../../scss/config/sizes" as s;
@use "../../scss/config/colors" as c;
@use "../../scss/config/z-indexes" as z;
@use "../../scss/config/breakpoints" as bp;
@use "../../scss/config/functions" as f;

$play-button-size: 91px;
$play-button-offset: ($play-button-size / 2) * -1;

.video-embed {
  &,
  &:before {
    background-size: cover;
    background-position: center;
    background-color: c.$base-text-color;
    background-repeat: no-repeat;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: z.$z-lowest;
    opacity: 1;
    transition: all .3s ease-in-out;
    background-blend-mode: soft-light;
  }


  &:hover,
  &:focus {

    &:before {
      opacity: 0;
    }

  }

  &:after {
    content: '';
    position: absolute;
    background: transparent no-repeat 0 0;
    background-size: $play-button-size;
    width: $play-button-size;
    height: $play-button-size;
    top: 50%;
    left: 50%;
    margin: $play-button-offset 0 0 $play-button-offset;
    z-index: z.$z-low;
  }

  &:not(.js-lazy-loading),
  &.lazy-loading-loaded {

    &:after {
      background-image: url('play-button.png');
    }

  }

  &--bottom-right {

    &:after {
      top: auto;
      left: auto;
      margin: auto;
      right: f.px-to-rem(10);
      bottom: f.px-to-rem(10);

      @media screen and (min-width: bp.$desktop) {
        right: f.px-to-rem(20);
        bottom: f.px-to-rem(20);
      }

    }

  }

}
